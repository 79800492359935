.touch-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 0.1em;
    color: #056FD0;
}

.tell-us-about {
    font-family: 'Epilogue';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #082F5E;
}

.share-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 170%;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.8;
}

.office-map {
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-message {
    background: #D05359;
    border-radius: 6px;
    border-style: none;
    color: white;
    width: 240px;
    height: 50px;
    font-family: Epilogue;
    font-weight: 600;
    font-size: 18px;
}

.form-control-height {
    height: 5rem;
}

.form-control-input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 1rem;
}

.map-height {
    min-width: 80%;
    max-height: 30rem;
    height: auto;
}