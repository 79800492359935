* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

.main-nav {
    width: 100%;
    height: 8rem;
    display: grid;
    grid-template-columns: 10rem 1fr 2fr 160px 10rem;
    background: #061425;
}

.nav-logo {
    width: 52px;
    height: 46px;
}

.kottage-logo {
    width: 124px;
    height: 18px;
}

.logo {
    height: 8rem;
    grid-column: 2/3;
    display: flex;
    justify-content: start;
    align-items: center;
}

.logo-spacing {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 3rem;
    font-family: 'Montserrat', sans-serif;
}


.menu-link {
    grid-column: 3/4;
}

.menu-link ul {
    height: 8rem;
    display: grid;
    justify-content: space-around;
    align-items: center;
    grid-template-columns: 3fr repeat(3, 1fr);
}

.menu-link ul li a {
    color: white;
    font-size: 18px;
    font-family: 'Epilogue', sans-serif;
}

.menu-link ul li:first-child {
    grid-column: 2/3;
}

.menu-link ul li a:hover {
    color: #FF7069;
}

.menu-link ul {
    text-align: left;
}

.social-media .hamburger-menu {
    display: none;
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 4/5;
    height: 8rem;
}

.social-media ul {
    height: 8rem;
    display: grid;
    grid-template-columns: 3fr repeat(4, 1fr);
    align-items: center;
}

.social-media ul {
    text-align: right;
}

.social-media ul li:first-child {
    grid-column: 2/3;
}

.nav-contact-btn {
    background-color: #D05359;
    border-style: none;
    color: white;
    width: 150px;
    height: 38px;
    font-family: Epilogue;
    font-weight: 500;
    font-size: 22px;
}


/* Terms and Conditions */

.term-container {
    margin: 10rem 0;
}

.end-user-license {
    font-family: Trebuchet MS !important;
    font-size: 26px !important;
    color: #000000 !important;
}

.body-text {
    font-family: Roboto, Arial;
    text-align: left;
    user-select: none;
    color: #595959;
    font-size: 11pt;
    line-height: 16.8667px;
}

.body-text-para {
    color: #595959;
    font-size: 14px;
    margin-top: 34px;
}

.term-heading {
    margin-top: 40px;
    text-align: left;
    font-family: Trebuchet MS !important;
    font-size: 19px !important;
    color: #000000 !important;
}

.term-generated-points {
    color: #595959;
    font-size: 14px;
}

ul.privacy-ul-list li {
    color: #595959;
    list-style: none;
}

ul.privacy-ul-list li:before {
    color: #595959;
    content: "\25AA";
    font-size: 1.5em;
    padding-right: .25em;
    position: relative;
    top: .1em;
}


/* responsive css style  */
@media (max-width: 1080px) {
    .main-nav {
        height: 8rem;
        grid-template-columns: 2rem 3fr 3fr 1fr 2rem;
    }

    .logo,
    .menu-link ul,
    .social-media ul {
        height: 8rem;
    }
}

/* responsive css style  */
@media (max-width: 998px) {
    .main-nav {
        height: 7rem;
        grid-template-columns: 2rem 2fr 3fr 2rem 2rem;
    }

    .menu-link {
        display: none;
    }

    .logo,
    .social-media ul {
        height: 7rem;
    }

    .mobile-menu-link {
        grid-column: 2/4;
        position: relative;
        z-index: 99;
    }

    .mobile-menu-link {
        background-color: #061425;
        height: 20rem;
        display: grid;
        grid-column: 2/5;
        align-items: center;
        padding-left: 3rem;
        transition: all 2s linear;
        transform-origin: top;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .mobile-menu-link ul {
        height: 20rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: start;
    }

    .mobile-menu-link ul li:first-child {
        transition-delay: 0.2s;
    }

    .social-media {
        grid-row: 1/2;
        grid-column: 3/5;
        justify-items: end;
        align-items: center;
        transition: all 2s linear;
    }

    .social-media .social-media-desktop {
        height: 0;
        display: none;
    }

    .nav-contact-btn {
        display: none;
    }

    .social-media {
        height: 7rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }

}

@media (max-width: 798px) {
    .main-nav {
        height: 6rem;
        grid-template-columns: 1rem 2fr 1fr 1fr 1rem;
    }

    .logo,
    .social-media ul {
        height: 6rem;
    }

    .social-media {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }
}

@media (max-width: 520px) {
    .main-nav {
        height: 6rem;
        grid-template-columns: 1rem 4fr 1fr 1fr 1rem;
    }

    .logo,
    .social-media ul {
        height: 6rem;
    }

    .logo h2 {
        font-size: 2rem;
    }

    .social-media {
        height: 6rem;
        display: flex;
        justify-self: end;
        align-items: center;
    }

    .social-media .hamburger-menu {
        display: block;
        font-size: 2.5rem;
    }

    /* hero section  */

    .hero-section h1 {
        font-size: 3.8rem;
    }
}


@media (max-width: 860px) {
    .header-title {
        font-size: 44px;
    }

    .header-title-red {
        font-size: 44px;
    }

    .header-subtitle {
        font-size: 44px;
    }
}

@media (max-width: 770px) {

    .right-app-descp {
        margin-bottom: 40px !important;
    }

    .card-media {
        margin-bottom: 40px !important;
    }

    .map-height {
        margin-top: 40px !important;
    }
}

@media (max-width: 767px) {
    .copyright {
        justify-content: center !important;
    }

    .powered {
        justify-content: center !important;
    }
}


@media (max-width: 458px) {

    .header-title {
        font-size: 24px !important;
    }

    .header-title-red {
        font-size: 24px !important;
    }

    .header-subtitle {
        font-size: 24px !important;
    }

    .header-container p {
        font-size: 12px !important;
    }

}


@media (max-width: 545px) {

    .header-title {
        font-size: 36px;
    }

    .header-title-red {
        font-size: 36px;
    }

    .header-subtitle {
        font-size: 36px;
    }

    .header-container p {
        font-size: 14px;
    }

}