.portfolio-container {
    width: 100%;
}

.portfolio-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #056FD0;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
}

.mobile-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Epilogue;
    font-weight: 700;
    font-size: 56px;
    color: #082F5E;
}

.grid-container {
    width: 90%;
    margin: 50px auto;
    display: grid;
    grid-template-rows: 550px 550px 550px;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
}

.gird-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gird-content {
    background-color: blue;
}

.card-media {
    width: 460px;
    height: 530px;
}

.left-img-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-app-descp {
    display: flex;
    justify-content: center;
    align-items: center;
}