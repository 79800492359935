.main-container {
    margin: 10rem 0;
}

.about-us {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #056FD0;
    font-family: Roboto;
    font-weight: 700;
    font-size: 30px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #082F5E;
    font-family: Epilogue;
    font-weight: 700;
    font-size: 56px;
    text-align: center;
}

.description {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Roboto;
    font-weight: 400;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.8;
}