.testimonial-container {
    margin-top: 10rem;
    background: linear-gradient(180deg, #FFFFFF 0%, #E1F3FF 100%);
}

.client-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #056FD0;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 30px;
}

.testimonial-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Epilogue;
    font-weight: 700;
    font-size: 56px;
    color: #082F5E;
}

.testimonial-grid {
    margin-top: 100px;
    width: 100%;
    display: grid;
    grid-template-columns: 10rem 1fr 1fr 1fr 10rem;
    height: 380px;
    margin-bottom: 20px;
    column-gap: 20px;
}

.feed-div1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    grid-column: 2/3;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(135, 146, 161, 0.16), 0px 6px 41px rgba(135, 146, 161, 0.11);
}

.feed-div2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    grid-column: 3/4;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(135, 146, 161, 0.16), 0px 6px 41px rgba(135, 146, 161, 0.11);
}

.feed-div3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    grid-column: 4/5;
    border-radius: 18px;
    box-shadow: 0px 4px 4px rgba(135, 146, 161, 0.16), 0px 6px 41px rgba(135, 146, 161, 0.11);
}

.feed-child-div {
    width: 80%;
    height: 80%;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 80px 1fr 100px;
}

.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 78px;
}

.user-name {
    margin-top: 10px;
    font-family: Epilogue;
    font-weight: 500;
    font-size: 18px;
    color: #1E266D;
}

.designation {
    font-family: Epilogue;
    font-weight: 200;
    font-size: 13px;
    color: #1E266D;
}

.feed-description {
    margin-top: 12px;
    grid-column-start: 1;
    grid-column-end: 3;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    color: #455880;
}

.feed-stars {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.box {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}