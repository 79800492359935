

.header-bg-container {
    background: linear-gradient(180deg, #061425 0%, #082F5E 100%), #333333;
}

.header-margin {
    margin: 10rem 0;
}

.header-container-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-title {
    font-family: Epilogue;
    color: white;
    font-weight: 700;
    font-size: 72px;
}

.header-title-red {
    font-family: Epilogue;
    color: #FF7069;
    font-weight: 700;
    font-size: 72px;
    margin-left: 10px;
    margin-right: 10px;
}

.header-subtitle {
    margin-top: 20px;
    font-family: Epilogue;
    color: white;
    font-weight: 700;
    font-size: 72px;
}

.header-container p {
    font-family: Epilogue;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}