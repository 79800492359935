.container-app {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: white;
}

.app-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
}

.app-icon-grid {
    display: grid;
    grid-template-columns: 130px 1fr;
    grid-template-rows: 130px 1fr 30px;
}

.app-name {
    font-family: Epilogue;
    font-weight: 700;
    font-size: 40px;
    color: #000F20;
}

.stars {
    width: 120px;
    height: 24px;
}

.app-description {
    margin-top: 12px;
    grid-column-start: 1;
    grid-column-end: 3;
    font-family: Roboto;
    font-weight: 400;
    font-size: 22px;
}

.see-more-btn {
    background-color: white;
    border-color: white;
    border-style: none;
    border-radius: 0;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    text-decoration-line: underline;
    text-transform: capitalize;
    color: #F54A33;
}