.testimonial-bg {
    background: linear-gradient(180deg, #FFFFFF 0%, #E1F3FF 100%);
}

.testimonial-card-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    border-style: none;
    border-radius: 18px;
    box-shadow: 0px 2px 4px 2px rgba(135, 146, 161, 0.11);
}

.testi-profile {
    width: 80px;
    height: 80px;
}

.slider-icon.left,
.slider-icon.right {
    height: 50px;
    width: 50px;
}

.slider-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.testi-user {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #1E266D;
}

.testi-desp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #455880;
    text-align: center;
}

.slick-prev {
    left: -80px !important;
    height: 80px !important;
    width: 80px !important;
}

.slick-next {
    right: -80px !important;
    width: 80px !important;
    height: 80px !important;
}