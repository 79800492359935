.footer-bg {
    background: #0A213E;
}

.footer-social-icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
}

.footer-company-desp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF
}

.footer-heading {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.footer-item {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
    color: #FFFFFF;
}

.footer-contact-icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.copyright {
    display: flex;
    justify-content: right;
    align-items: right;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.powered {
    display: flex;
    justify-content: left;
    align-items: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.vr {
    border-left: 1px solid white;
    height: 30px;
}

.vr-line {
    display: flex;
    justify-content: center;
    align-items: center;
}

.privacy-terms-hover a:hover {
    color: #FF7069;
}

.quicklink-hover:hover {
    color: #FF7069;
}