.app-logo {
    width: 44px;
    height: 44px;
}

.app-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.app-desp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
}

.take-look-btn {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    color: #056FD0;
    border-style: none;
    background-color: white;
    width: 126px;
    height: 36px;
    border: 0.5px solid #056FD0;
    border-radius: 5px;
    box-sizing: border-box;
}

.blue-shadow-container {
    border-style: none;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 2px rgba(5, 111, 208, 0.25);
    border-radius: 5px;
}

.hr-line {
    border: 0.5px solid #DADADA
}

.app-design-bg {
    background-color: #F8F8F8;
}